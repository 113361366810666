@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import "./bootstrap.scss";

html {
	height: 100%;
}

body {
	font-family: "Inter", sans-serif !important;
	height: 100svh !important;
	background-color: #f3f4f6;
	margin: 0px !important;
	font-size: 13px;
}

a {
	text-decoration: none;
}

#root {
	height: 100svh;
	display: flex;
	justify-content: center;
	align-items: center;
	--primary: rgb(0, 77, 113);
	--secondary: rgb(0, 77, 113);
	--primary-rgb: 0, 77, 113;
	--secondary-rgb: 0, 77, 113;
	--dsg-selection-border-color: rgb(0, 77, 113) !important;

	:root {
		--bs-blue: #0d6efd;
		--bs-indigo: #6610f2;
		--bs-purple: #6f42c1;
		--bs-pink: #d63384;
		--bs-red: #dc3545;
		--bs-orange: #fd7e14;
		--bs-yellow: #ffc107;
		--bs-green: #198754;
		--bs-teal: #20c997;
		--bs-cyan: #0dcaf0;
		--bs-black: #000;
		--bs-white: #fff;
		--bs-gray: #6c757d;
		--bs-gray-dark: #343a40;
		--bs-gray-100: #f8f9fa;
		--bs-gray-200: #e9ecef;
		--bs-gray-300: #dee2e6;
		--bs-gray-400: #ced4da;
		--bs-gray-500: #adb5bd;
		--bs-gray-600: #6c757d;
		--bs-gray-700: #495057;
		--bs-gray-800: #343a40;
		--bs-gray-900: #212529;
		--bs-primary: #004D71;
		--bs-secondary: #004D71;
		--bs-success: #198754;
		--bs-info: #0dcaf0;
		--bs-warning: #ffc107;
		--bs-danger: #dc3545;
		--bs-light: #f8f9fa;
		--bs-dark: #374151;
		--bs-primary-rgb: 0, 77, 113;
		--bs-secondary-rgb: 0, 77, 113;
		--bs-success-rgb: 25, 135, 84;
		--bs-info-rgb: 13, 202, 240;
		--bs-warning-rgb: 255, 193, 7;
		--bs-danger-rgb: 220, 53, 69;
		--bs-light-rgb: 248, 249, 250;
		--bs-dark-rgb: 55, 65, 81;
		--bs-white-rgb: 255, 255, 255;
		--bs-black-rgb: 0, 0, 0;
		--bs-body-color-rgb: 33, 37, 41;
		--bs-body-bg-rgb: 255, 255, 255;
		--bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		--bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
		--bs-gradient: linear-gradient(180deg, hsla(0, 0%, 100%, .15), hsla(0, 0%, 100%, 0));
		--bs-body-font-family: var(--bs-font-sans-serif);
		--bs-body-font-size: 1rem;
		--bs-body-font-weight: 400;
		--bs-body-line-height: 1.5;
		--bs-body-color: #212529;
		--bs-body-bg: #fff;
		--bs-border-width: 1px;
		--bs-border-style: solid;
		--bs-border-color: #dee2e6;
		--bs-border-color-translucent: rgba(0, 0, 0, .175);
		--bs-border-radius: 0.375rem;
		--bs-border-radius-sm: 0.25rem;
		--bs-border-radius-lg: 0.5rem;
		--bs-border-radius-xl: 1rem;
		--bs-border-radius-2xl: 2rem;
		--bs-border-radius-pill: 50rem;
		--bs-link-color: #374151;
		--bs-link-hover-color: #2c3441;
		--bs-code-color: #d63384;
		--bs-highlight-bg: #fff3cd;
	}
}

.weoll-sign-btn {
	padding: 9px 16px;
	color: #45464E;
	;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #45464E;
	border-radius: 5px;
	background-color: white;
	font-size: 16px;
	cursor: pointer;
	font-weight: 500;
	width: 300px;
}

.weoll-sign-btn:hover {
	background-color: rgba(207, 217, 222, 0.1);
}

.weoll-logo-lg {
	display: block;
	height: 36px;
	margin-top: 32px;
	margin-bottom: 32px;
}

.weoll-logo-sm {
	display: none;
	height: 42px;
	margin-top: 32px;
	margin-bottom: 32px;
}

#mainWrapper {
	display: flex;
	width: 100%;
	justify-content: center;
}

#mainContainer {
	display: flex;
	flex-grow: 1;
	flex-direction: row;
	height: 100% !important;
	max-width: 1000px;
	min-height: 500px;
}

#secondWrapper {
	background-color: white;
	border-left: none;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	border-radius: 0 20px 20px 0;
	-webkit-border-radius: 0 20px 20px 0;
	-moz-border-radius: 0 20px 20px 0;
	-ms-border-radius: 0 20px 20px 0;
	-o-border-radius: 0 20px 20px 0;
}

.weoll-img {
	min-width: 427px;
	border-radius: 20px 0 0 20px;
	-webkit-border-radius: 20px 0 0 20px;
	-moz-border-radius: 20px 0 0 20px;
	-ms-border-radius: 20px 0 0 20px;
	-o-border-radius: 20px 0 0 20px;
}

.weoll-bg-img {
	background-image: url("https://storage.googleapis.com/weoll-backend.appspot.com/media-content/illustration-pack/loginSplash.webp");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 18px 0 0 0 !important;
	-webkit-border-radius: 18px 0 0 0;
	-moz-border-radius: 18px 0 0 0;
	-ms-border-radius: 18px 0 0 0;
	-o-border-radius: 18px 0 0 0;
}

.footer-wrapper {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	height: 80px;
}

.copyright {
	font-size: 14px;
	line-height: 19px;
	color: #FFFFFF;
	min-width: 257px;
}

.social-media-icons {
	display: flex;
	gap: 15px;
}

@media only screen and (max-width: 999px) {
	.weoll-logo-lg {
		display: none;
	}

	.weoll-logo-sm {
		display: block;
	}

	#mainWrapper {
		height: 100%;
		display: block;
	}

	#mainContainer {
		flex-direction: column-reverse;
	}

	#firstWrapper {
		/* d none */
		width: unset;
	}

	#secondWrapper {
		border-radius: 0;
		border-bottom: none;
	}

	.weoll-img {
		min-width: unset;
		display: none;
		border-radius: 0 !important;
		-webkit-border-radius: 0 !important;
		-moz-border-radius: 0 !important;
		-ms-border-radius: 0 !important;
		-o-border-radius: 0 !important;
	}

	.footer-wrapper {
		height: 94px;
	}
}

@media only screen and (max-width: 409px) {
	.copyright {
		font-size: 13px;
		line-height: 19px;
		color: #FFFFFF;
		min-width: unset;
	}
}

.lang-menu-btn .arrow>g {
	fill: #45464E;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	-webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	-moz-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	-ms-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	-o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.lang-menu-btn[aria-expanded="false"]:hover .arrow>g {
	fill: #ffffff !important;
}

.lang-menu-btn[aria-expanded="false"]:hover {
	background: var(--bs-primary) !important;
	color: #fff !important;
}


.dropdown-item.active {
	background: var(--bs-gray-200) !important;
	color: var(--bs-dark) !important;
}


.iti__selected-flag {
	border-radius: 4px 0px 0px 4px;
	border-right: 1px solid var(--bs-gray-6006-c-757-d, #6C757D);
	background: #EAECEF;
	// position: relative !important;
}

// .iti__country-list {
// 	bottom: -32px;
// }

// // .iti__country-list {
// // 	position: absolute;
// // }
.iti-flag {
	background-image: url("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/10.0.2/img/flags.png");
}

// .iti-mobile .intl-tel-input .flag-container {
// 	position: static !important;

// }

// .iti-mobile .intl-tel-input .selected-flag {
// 	position: absolute !important;
// 	top: 50% !important;
// 	transform: translateY(-50%) !important;
// 	height: 10% !important;
// }

// .iti-mobile .intl-tel-input .country-list {
// 	width: 100% !important;
// 	top: 100% !important;
// }

#phone-input-container .iti--container {
	top: 32px !important;
	left: 0 !important;
  height: 50px;
	width: 294px;
  position: absolute !important;
}