/*Customize Bootsrap for WEOLL*/

$primary: #004D71 !default;
$secondary: #004D71 !default;
$success: #198754 !default;
// $success: #71b6b7 !default;
$info: #0dcaf0 !default;
$warning: #ffc107 !default;
$danger: #dc3545 !default;
$light: #f8f9fa !default;
$dark: #374151 !default;

$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"success": $success,
	"info": $info,
	"warning": $warning,
	"danger": $danger,
	"light": $light,
	"dark": $dark,
	) !default;


$input-font-size: 12px;
$btn-font-size: 12px;
$btn-font-weight: 500;
$dropdown-font-size: 13px;
$grid-gutter-width: 0.5rem !default;
$progress-bar-bg: var(--primary
);

.ratio-11x4 {
	--bs-aspect-ratio: 36.363636363%;
}

@import "~bootstrap/scss/bootstrap";

a {
	color: inherit;

	&:hover {
		color: inherit;
	}
}

/*** BOOTSTRAP BUTTON VARIANTS ***/

.btn {
	font-size: 13px;
}

.btn-primary {
	background-color: var(--bs-gray-100);
	border-color: var(--bs-gray-100);
	color: var(--primary);
	border: none;

	&:hover {
		background-color: rgba(var(--primary-rgb), 0.9);
		border-color: rgba(var(--primary-rgb), 0.9);
	}

	;

	&:focus:not(:focus-visible) {
		background-color: var(--bs-gray-100);
		color: var(--primary);
	}
}

.btn-secondary {
	color: white;
	background-color: var(--bs-gray-100);
	border-color: var(--bs-gray-100);
	color: var(--secondary);
	border: none;

	&:hover {
		color: white;
		background-color: var(--secondary);
		border-color: var(--secondary);
	}

	;

	&:focus:not(:focus-visible) {
		background-color: var(--bs-gray-100);
		color: var(--secondary);
	}
}

.btn-outline-primary {
	background-color: white;
	border-color: var(--primary);
	color: var(--primary);

	&:hover {
		color: white;
		background-color: var(--primary);
		border-color: var(--primary);
	}

	;

	&:focus:not(:focus-visible) {
		background-color: white;
		border-color: var(--primary);
		color: var(--primary);
	}
}

.btn-outline-secondary {
	background-color: white;
	border-color: var(--secondary);
	color: var(--secondary);

	&:hover {
		color: white;
		background-color: var(--secondary);
		border-color: var(--secondary);
	}

	;

	&:focus:not(:focus-visible) {
		background-color: white;
		border-color: var(--secondary);
		color: var(--secondary);
	}
}

.btn-light {
	color: var(--bs-primary);
	background: none;
	border: none;

	&:focus:not(:focus-visible) {
		background: none;
		color: var(--bs-primary);
	}

	;

	&:hover {
		background-color: var(--bs-primary);
		color: white;
	}

	;
}

.btn-outline-light {
	border: 1px solid var(--bs-primary);
	color: var(--bs-primary);
	background-color: white;

	&:hover {
		background-color: var(--bs-primary);
		color: white;
	}

	&:focus:not(:focus-visible) {
		border-color: var(--bs-primary);
	}
}

/*** BOOTSTRAP BUTTON VARIANTS ***/


/*** WEOLL BUTTONS VARIANTS ***/

/* change class name */
.btn-weoll-icon {
	color: #45464E;
	font-weight: 500;
	text-decoration: none;
	padding: 0 !important;

	&:hover {
		color: var(--secondary);
		// background-color: var(--bs-gray-100);
		padding: 0 !important;
	}

	&:focus:not(:focus-visible) {
		color: var(--secondary);
		border: none;
		box-shadow: none !important;
		outline: none !important;
		padding: 0 !important;
	}
}

.btn-weoll-primary {
	background-color: var(--primary);
	color: var(--bs-white);
	border: none;

	&:hover {
		color: var(--bs-white) !important;
		background-color: rgba(var(--primary-rgb), 0.8);
	}

	&:disabled {
		color: var(--bs-white) !important;
		background-color: var(--primary);
		opacity: 0.4;
	}

	&:focus:not(:focus-visible) {
		background-color: var(--primary);
		color: var(--bs-white);
		border: none;
	}
}

.btn-weoll-primary-soft {
	background-color: rgba(var(--primary-rgb), 0.1);
	color: var(--primary);
	border: none;

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--primary) !important;
	}

	&:disabled {
		color: var(--primary) !important;
		background-color: rgba(var(--primary-rgb), 0.2);
		opacity: 0.4;
	}
}

.btn-weoll-outline-primary {
	background-color: var(--bs-white);
	border-color: var(--primary);
	color: var(--primary);

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--primary) !important;
		border-color: var(--primary);
	}

	&:disabled {
		background-color: var(--bs-white) !important;
		color: var(--bs-gray-600) !important;
		border-color: var(--bs-gray-600) !important;
	}
}

.btn-weoll-outline-primary-soft {
	background-color: rgba(var(--primary-rgb), 0.1);
	color: var(--primary);
	border-color: var(--primary);

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--primary) !important;
	}

	&:disabled {
		color: var(--primary) !important;
		background-color: rgba(var(--primary-rgb), 0.2);
		opacity: 0.4;
	}
}

.btn-weoll-secondary {
	background-color: var(--secondary);
	color: var(--bs-white);
	border: none;

	&:hover {
		color: var(--bs-white) !important;
		background-color: rgba(var(--secondary-rgb), 0.8) !important;
	}

	&:disabled {
		color: var(--bs-white) !important;
		background-color: var(--secondary);
		opacity: 0.4;
	}
}

.btn-weoll-secondary-soft {
	background-color: rgba(var(--secondary-rgb), 0.1);
	color: var(--secondary);
	border: none;

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--secondary) !important;
	}

	&:disabled {
		color: var(--secondary) !important;
		background-color: rgba(var(--secondary-rgb), 0.2);
		opacity: 0.4;
	}
}

.btn-weoll-outline-secondary {
	background-color: var(--bs-white);
	border-color: var(--secondary);
	color: var(--secondary);

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--secondary) !important;
		border-color: var(--secondary);
	}

	&:disabled {
		background-color: var(--bs-white) !important;
		color: var(--bs-gray-600) !important;
		border-color: var(--bs-gray-600) !important;
	}
}

.btn-weoll-outline-secondary-soft {
	background-color: rgba(var(--secondary-rgb), 0.1);
	color: var(--secondary);
	border-color: var(--secondary);

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--secondary) !important;
	}

	&:disabled {
		color: var(--secondary) !important;
		background-color: rgba(var(--secondary-rgb), 0.2);
		opacity: 0.4;
	}
}

.btn-weoll-warning {
	background-color: var(--bs-warning);
	color: var(--bs-white);
	border: none;

	&:hover {
		color: var(--bs-white) !important;
		background-color: rgba(var(--bs-warning-rgb), 0.8) !important;
	}

	&:disabled {
		color: var(--bs-white) !important;
		background-color: var(--bs-warning);
		opacity: 0.4;
	}
}

.btn-weoll-outline-warning {
	background-color: var(--bs-white);
	border-color: var(--bs-warning);
	color: var(--bs-warning);

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--bs-warning) !important;
		border-color: var(--bs-warning);
	}

	&:disabled {
		background-color: var(--bs-white) !important;
		color: var(--bs-gray-600) !important;
		border-color: var(--bs-gray-600) !important;
	}
}

// DARK & LIGHT BUTTONS START

.btn-weoll-dark {
	background-color: var(--bs-dark);
	color: var(--bs-white);
	border: none;

	&:hover {
		color: var(--bs-white) !important;
		background-color: rgba(var(--bs-dark-rgb), 0.8) !important;
	}

	&:disabled {
		background-color: var(--bs-dark);
		color: var(--bs-white);
		opacity: 0.4;
	}
}

.btn-weoll-outline-dark {
	background-color: var(--bs-white);
	color: var(--bs-dark);
	border: 1px solid var(--bs-dark);

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--bs-dark) !important;
	}

	&:disabled {
		background-color: var(--bs-white) !important;
		color: var(--bs-gray-600) !important;
		border-color: var(--bs-gray-600) !important;
	}
}

.btn-weoll-light {
	background-color: var(--bs-white);
	// border-color: var(--primary);
	border: none;
	color: var(--primary);

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--primary) !important;
		border-color: var(--primary);
	}

	&:disabled {
		background-color: var(--bs-dark);
		color: var(--bs-white);
		opacity: 0.4;
	}
}

.btn-weoll-outline-light {
	background-color: var(--bs-white);
	border-color: var(--bs-dark);
	color: var(--primary);

	&:hover {
		color: var(--bs-white) !important;
		background-color: var(--primary) !important;
		border-color: var(--primary);
	}

	&:disabled {
		background-color: var(--bs-white) !important;
		color: var(--bs-gray-600) !important;
		border-color: var(--bs-gray-600) !important;
	}
}

.btn-weoll-icon-primary {
	background-color: var(--primary);
	border: none;
	color: var(--bs-white);
	padding-left: 6px !important;
	padding-right: 6px !important;

	&:hover {
		color: var(--bs-white) !important;
		background-color: rgba(var(--primary-rgb), 0.8) !important;
	}

	&:disabled {
		color: var(--bs-white) !important;
		background-color: var(--primary);
		opacity: 0.4;
	}
}

.btn-weoll-icon-light {
	background-color: transparent;
	border: none;
	color: var(--bs-dark);
	padding-left: 6px !important;
	padding-right: 6px !important;

	&:hover {
		background-color: rgba(var(--primary-rgb), 0.1) !important;
		color: var(--primary) !important;
		border: none;
	}

	&:disabled {
		color: var(--bs-gray) !important;
	}
}

.btn-weoll-link-light {
	background-color: transparent;
	border: none;
	color: var(--bs-dark);

	&:hover {
		background-color: rgba(var(--primary-rgb), 0.1) !important;
		color: var(--primary) !important;
		border: none;
	}

	&:disabled {
		color: var(--bs-gray) !important;
	}
}

.btn-weoll-link-primary {
	background-color: var(--primary);
	border: none;
	color: var(--bs-white);

	&:hover {
		color: var(--bs-white) !important;
		background-color: rgba(var(--primary-rgb), 0.8) !important;
	}

	&:disabled {
		color: var(--bs-white) !important;
		background-color: var(--primary);
		opacity: 0.4;
	}
}

.btn-weoll-close-button {
	padding: 0 !important;
	border: none;
	color: #DBDBDB;

	&:hover {
		color: var(--bs-black) !important;
	}

	// &:disabled {
	//   color: var(--bs-white) !important;
	// }
}

/*** WEOLL BUTTONS VARIANTS ***/

/* FLOAT BUTTON FOR SMALL SCREEN */

@media only screen and (max-width: 991px) {
	.float-button-s {
		position: fixed;
		bottom: 16px;
		right: 16px;
	}
}

/* FLOAT BUTTON FOR SMALL SCREEN */

.btn-link {
	color: var(--secondary);
	font-weight: 500;
	text-decoration: none;

	&:hover {
		color: var(--secondary) !important;
		background-color: var(--bs-gray-100) !important;
	}

	&:focus:not(:focus-visible) {
		color: var(--secondary);
	}
}

/* INPUT STYLES */

.form-label,
.col-form-label {
	color: var(--bs-gray-600) !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 19px !important;
	padding: 4px 0 !important;
	margin: 0 !important;
}

.form-check-label {
	// form-check-label m-auto
	display: flex;
	gap: 4px;
}

.form-control {
	border-color: var(--bs-gray-600);
	font-size: 14px !important;
	color: var(--bs-gray-700) !important;
	// padding: 5px 12px 5px 1rem;
	line-height: 20px !important;
	border-radius: var(--bs-border-radius);
	-webkit-border-radius: var(--bs-border-radius);
	-moz-border-radius: var(--bs-border-radius);
	-ms-border-radius: var(--bs-border-radius);
	-o-border-radius: var(--bs-border-radius);

	&:focus {
		box-shadow: inset 0px 0px 0px 3px rgba(var(--primary-rgb), 0.2);
		outline: 0 none;
		border: 1px solid var(--primary);
	}

	&::placeholder {
		color: var(--bs-gray-500) !important;
		font-size: 14px !important;
	}

	&:disabled {
		background-color: var(--bs-gray-200) !important;
		border: 1px solid var(--bs-gray-400) !important;
		color: var(--bs-gray-500) !important;
	}

	&.selection.dropdown[disabled="disabled"] {
		background-color: var(--bs-gray-200) !important;
		border: 1px solid var(--bs-gray-400) !important;
		color: var(--bs-gray-500) !important;
		background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.11508 0.295078L0.705078 1.70508L6.70508 7.70508L12.7051 1.70508L11.2951 0.295078L6.70508 4.87508L2.11508 0.295078Z' fill='%23ADB5BD'/%3E%3C/svg%3E%0A") !important;
		background-size: 12px 7.41px !important;
		background-position: right 1rem center;
		background-repeat: no-repeat !important;
	}

}

textarea.form-control::placeholder {
	color: var(--bs-gray-500) !important;
	font-size: 14px !important;
	line-height: 20px !important;
}

.input-group-text {
	color: var(--bs-gray-800);
	font-size: 14px;
	padding: 5px 0.75rem;
	line-height: 20px;
	background-color: #EAECEF;
	border-color: var(--bs-gray-600) !important;
}

.form-text {
	font-size: 12px !important;
	line-height: 16px !important;
	font-weight: 300;
}

/* tooltip css */

.fa-question-circle:before {
	font-family: 'Material Design Icons' !important;
	content: '\F02FD' !important;
	font-size: 16px !important;
	line-height: 19px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--bs-gray-500);
}

.form-check-label>.fa.fa-question-circle {
	font-family: 'Material Design Icons' !important;
	content: '\F02FD' !important;
	font-size: 16px !important;
	line-height: 23px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--bs-gray-500);
}

/* tooltip css */

// .form-control:focus:isinvalid {
//   box-shadow: none !important;
//   // border-color: var(--primary);
// }

.form-select {
	border-color: var(--bs-gray-600);
	font-size: 14px !important;
	color: var(--bs-gray-700);
	padding: 5px 12px 5px 1rem;
	line-height: 20px !important;
	background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.11508 0.295078L0.705078 1.70508L6.70508 7.70508L12.7051 1.70508L11.2951 0.295078L6.70508 4.87508L2.11508 0.295078Z' fill='%236C757D'/%3E%3C/svg%3E%0A");
	background-size: 12px 7.41px;
	background-position: right 1rem center;

	&:focus {
		box-shadow: inset 0px 0px 0px 3px rgba(var(--primary-rgb), 0.2);
		outline: 0 none;
		border: 1px solid var(--primary);
		background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.8849 7.70492L12.2949 6.29492L6.29492 0.294922L0.294922 6.29492L1.70492 7.70492L6.29492 3.12492L10.8849 7.70492Z' fill='%236C757D'/%3E%3C/svg%3E%0A");
	}

	&:disabled {
		border-color: var(--bs-gray-400);
		background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.11508 0.295078L0.705078 1.70508L6.70508 7.70508L12.7051 1.70508L11.2951 0.295078L6.70508 4.87508L2.11508 0.295078Z' fill='%23ADB5BD'/%3E%3C/svg%3E%0A");
	}
}

// .form-check-input[type=checkbox] .is-invalid {
//     float: unset !important;
//    margin-left: -0rem !important;
// }

.form-check-input {
	width: 16px;
	height: 16px;
	cursor: pointer;
	margin-right: 4px !important;

	/* Radio */
	&[type=radio] {
		position: relative;
		border: 2px solid var(--primary);
		cursor: pointer;
		// margin-right: 4px !important;
	}

	&[type=radio]:hover {
		// box-shadow: 0px 0px 0px 4px rgba(13, 110, 253, 0.6);
		box-shadow: 0px 0px 0px 4px rgba(73, 80, 87, 0.1) !important;
		background: rgba(73, 80, 87, 0.1) !important;
		border: 2px solid var(--bs-gray-700) !important;
	}

	&[type=radio]::after {
		content: '';
		display: block;
		height: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: var(--bs-white);
		width: 8px;
		height: 8px;
		border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
	}

	&[type=radio]:hover::after {
		background: transparent;
	}

	&:checked[type=radio] {
		background-image: unset;
		background: white;
	}

	&:checked[type=radio]:hover:not(:disabled) {
		background: rgba(var(--primary-rgb), 0.2) !important;
		box-shadow: 0px 0px 0px 4px rgba(var(--primary-rgb), 0.2) !important;

	}

	&:checked[type=radio]::after {
		content: '';
		display: block;
		height: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: var(--primary);
		transition: all 0.200s ease;
		-webkit-transition: all 0.200s ease;
		-moz-transition: all 0.200s ease;
		-ms-transition: all 0.200s ease;
		-o-transition: all 0.200s ease;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
	}

	/* Checkbox and radio default settings */
	&:checked:hover,
	&:hover {
		box-shadow: 0px 0px 0px 4px rgba(var(--primary-rgb), 0.2) !important;
		// box-shadow: 0px 0px 0px 4px rgba(var(--primary-rgb), 0.2) !important;
		border-color: var(--primary) !important;
	}

	&:not(:checked):disabled:hover {
		box-shadow: unset !important;
		border-color: var(--bs-gray-700) !important;
		cursor: not-allowed !important;
		background-color: white !important;
	}

	&:checked:disabled:hover {
		box-shadow: unset !important;
		// border-color: var(--bs-gray-700) !important;
		cursor: not-allowed !important;
	}

	&:checked[type=checkbox] {
		background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.33333 7.83333L0 4.5L1.16667 3.33333L3.33333 5.5L8.83333 0L10 1.16667L3.33333 7.83333Z' fill='white'/%3E%3C/svg%3E%0A");
		background-size: 10px 7.83px;
	}

	&:checked {
		background-color: var(--primary);
		border-color: var(--primary) !important;
	}

	&:focus {
		box-shadow: none;
	}

	// &[name="data[Checkbox]"] {
	//   margin-right: 4px !important;
	// }
}

.form-check.checkbox {
	margin-bottom: 0 !important;

	&>.form-check-label {
		margin-bottom: 0 !important;
	}


}

/* Formio Styles */

.choices {
	&[data-type*='select-one']:after {
		background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.11508 0.295078L0.705078 1.70508L6.70508 7.70508L12.7051 1.70508L11.2951 0.295078L6.70508 4.87508L2.11508 0.295078Z' fill='%236C757D'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-size: 12px 7.41px !important;
		width: 16px !important;
		height: 16px !important;
		border-style: unset !important;
		border-color: unset !important;
		border-width: unset !important;
	}

	&[data-type*='select-one'].is-open:after {
		background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.8849 7.70492L12.2949 6.29492L6.29492 0.294922L0.294922 6.29492L1.70492 7.70492L6.29492 3.12492L10.8849 7.70492Z' fill='%236C757D'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		margin-top: -2.5px !important;
	}

	&[data-type*='select-one'] .choices__input {
		border-color: var(--bs-gray-600) !important;
	}

	&[data-type*='select-one'] .choices__input::placeholder {
		color: var(--bs-gray-500) !important;
		font-size: 14px !important;
	}

	&[data-type*='select-one'] .choices__button {
		background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1.20857L10.7914 0L6 4.79143L1.20857 0L0 1.20857L4.79143 6L0 10.7914L1.20857 12L6 7.20857L10.7914 12L12 10.7914L7.20857 6L12 1.20857Z' fill='%23ADB5BD'/%3E%3C/svg%3E%0A");
		background-size: 12px;
		opacity: 1;
		margin-right: 30px;
	}

	&[data-type*='select-one'] .choices__button:hover {
		background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1.20857L10.7914 0L6 4.79143L1.20857 0L0 1.20857L4.79143 6L0 10.7914L1.20857 12L6 7.20857L10.7914 12L12 10.7914L7.20857 6L12 1.20857Z' fill='%23343A40'/%3E%3C/svg%3E%0A");
	}
}

.choices__item.choices__item--selectable {
	overflow: unset !important;
}

.choices__list--dropdown {
	border-color: var(--bs-gray-600) !important;
	border-radius: 0.375rem !important;
	-webkit-border-radius: 0.375rem !important;
	-moz-border-radius: 0.375rem !important;
	-ms-border-radius: 0.375rem !important;
	-o-border-radius: 0.375rem !important;
}


/* ERRORS */
.is-invalid,
.invalid-feedback {
	font-size: 12px !important;
	font-weight: 300 !important;
	border-color: #D9214E !important;
	display: block;
}

.form-control:invalid,
.form-control.is-invalid {
	font-size: 14px !important;
	border-color: #D9214E !important;
	// padding: 5px 12px 5px 1rem;
	background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.2 12.4H10.8V14H9.2V12.4ZM9.2 6H10.8V10.8H9.2V6ZM10 2C5.576 2 2 5.6 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94943 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 8.94943 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997 5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM10 16.4C8.30261 16.4 6.67475 15.7257 5.47452 14.5255C4.27428 13.3253 3.6 11.6974 3.6 10C3.6 8.30261 4.27428 6.67475 5.47452 5.47452C6.67475 4.27428 8.30261 3.6 10 3.6C11.6974 3.6 13.3253 4.27428 14.5255 5.47452C15.7257 6.67475 16.4 8.30261 16.4 10C16.4 11.6974 15.7257 13.3253 14.5255 14.5255C13.3253 15.7257 11.6974 16.4 10 16.4Z' fill='%23D9214E'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-position: right 12px center;
	background-size: 1rem;
}

.form-select:disabled {
	color: var(--bs-gray-500);
}

.form-select:invalid,
.form-select.is-invalid {
	font-size: 14px !important;
	border-color: #D9214E !important;
}

.form-control:invalid:focus,
.form-control.is-invalid:focus,
.form-select:invalid:focus,
.form-select.is-invalid:focus {
	box-shadow: inset 0px 0px 0px 3px rgba(217, 33, 78, 0.2);
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
	background-image: url("data:image/svg+xml,%3Csvg width=%2713%27 height=%278%27 viewBox=%270 0 13 8%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M2.11508 0.295078L0.705078 1.70508L6.70508 7.70508L12.7051 1.70508L11.2951 0.295078L6.70508 4.87508L2.11508 0.295078Z%27 fill=%27%236C757D%27/%3E%3C/svg%3E%0A") !important;
	background-size: 12px 7.41px !important;
}

.formio-errors .error,
.formio-error-wrapper,
.formio-error-wrapper .field-required:after,
.field-required:after,
.tab-error::after {
	color: #D9214E !important;
}

.form-control.dropdown.is-invalid {
	background-image: unset !important;
}

/* ERRORS */

/* INPUT STYLES */

.form-check-input,
.form-checked {
	border-color: var(--bs-gray-700) !important;
}

.form-switch .form-check-input:checked {
	background-position: right center;
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
	background-size: unset;
}

.form-switch .form-check-input:not(:checked) {
	background-position: left center;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23495057'/%3E%3C/svg%3E") !important;
}

.form-switch .form-check-input:not(:checked):hover {
	background-position: left center;
	// background: var(--primary);
	box-shadow: 0px 0px 0px 4px rgba(73, 80, 87, 0.1) !important;
	border-color: var(--bs-gray-700) !important;
	// background: rgba(73, 80, 87, 0.1) !important;
	// border: 1px solid var(--bs-gray-700) !important;
	// background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='var(--primary)'/%3E%3C/svg%3E") !important;
	// mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3'/%3E%3C/svg%3E") !important;
}

.formio-error-wrapper,
.formio-warning-wrapper {
	padding: 0;
	background: none !important;
}

.form-group>div>.card.card-body {
	border-color: var(--bs-gray-400) !important;
}

// .has-error > .choices[data-type*=select-one]:after {
//   padding-right: 32px;
// }

/* multiple input */
.multiple-value-text-input-item-container {
	display: flex;

}

.multiple-value-text-input-item-container>p {
	display: flex;
	flex-wrap: wrap;
	gap: 0.25rem;
	margin-bottom: 4px;
}

.multiple-value-text-input-item {
	background-color: var(--bs-gray-200) !important;
	border-radius: 0.5rem;
	-webkit-border-radius: 0.5rem;
	-moz-border-radius: 0.5rem;
	-ms-border-radius: 0.5rem;
	-o-border-radius: 0.5rem;
	padding: 0.25rem 0.5rem !important;
	margin: 0 !important;
}

label[for=mailTo] {
	margin-bottom: 0;
}

.form-check-label {
	display: flex;
	gap: 4px;
	font-size: 14px;
	line-height: 23px;
	margin-top: auto;
	margin-bottom: 0;
}

.choices__list:not(:disabled) {
	color: var(--bs-gray-700) !important;
}

.formio-editor-read-only-content {
	color: var(--bs-gray-500) !important;
	font-size: 14px !important;
	margin: -9px -4px -9px 0px;
	line-height: 20px !important;
}

/* tooltip */
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
	left: unset;
	width: 0;
}

.tooltip[x-placement='right'] .tooltip-inner {
	margin-left: 0;
}

.tooltip[x-placement='right'] .tooltip-arrow {
	border-right: 5px solid #000;
}


/* Core checkbox and radio */
.form-check {
	&>.form-check-input {
		&[type=checkbox] {
			margin-right: 8px;
		}

		&[type=radio] {
			margin-right: 8px;
		}
	}
}

.form-check-label>span {
	font-size: 14px;
	line-height: 23px;
	margin-top: auto;
}

.dropdown-toggle::after,
.dropdown-toggle::before {
	display: none !important;
}

.btn:focus {
	box-shadow: none !important;
}

.input-group>.input-group-prepend~.form-control {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.input-group>.input-group-append~.form-control {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.input-group-append {
	z-index: 100 !important;
}

.input-group-prepend {
	z-index: 100 !important;
}

.form-check-input.is-invalid~span {
	color: #363639 !important;
}

.form-check-input[type="checkbox"].is-invalid:focus:active {
	box-shadow: 0px 0px 0px 4px rgba(var(--primary-rgb), 0.2);
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
	box-shadow: 0px 0px 0px 4px rgba(var(--primary-rgb), 0.2) !important;
}

.form-check-input[type="checkbox"][name="data[Checkbox]"] {
	margin-left: -1.2rem !important;
}

.form-check-input.is-invalid[type="checkbox"][name="data[Checkbox]"]:checked {
	background-color: var(--primary) !important;
}

.form-select {
	padding: 5px 3rem 5px 1rem;
}

// .form-control.is-invalid {
// 	// padding: 5px 2.25rem 5px 1rem;
// }

/* FORM FLOW ACTION MODAL ICIN COMPONENTLER SONRA STYLE LERİ DEĞİŞTİRMEK GEREKECEK */

.btn-weoll-tab-link-primary {
	color: var(--primary);
	border: none !important;
	font-weight: 900;
	font-size: 20px;
	line-height: 27px;

	&:focus {
		border: none !important;
	}

	&:hover {
		color: var(--primary);
	}
}

.btn-weoll-tab-link {
	color: #707070;
	border: none !important;
	font-weight: 900;
	font-size: 20px;
	line-height: 27px;

	&:focus {
		border: none !important;
	}

	&:hover {
		color: #707070;
	}
}

.input-icon {
	padding: 5px 12px 5px 44px !important;
}

option[value=""][disabled] {
	display: none;
}

// required field kayması
.form-check>.form-check-label>.form-check-input {
	float: unset !important;
	margin-left: -1.21rem !important;
	margin-top: 0.25rem !important;
}

.alert-weoll-success {
	background-color: rgba(88, 197, 188, 0.20);
	color: var(--success, #58C5BC);
	text-align: center;
	font-size: 13px;
	font-weight: 400;
}

.alert-weoll-error {
	background: rgba(224, 20, 7, 0.20);
	color: var(--red, #E01407);
	text-align: center;
	font-size: 13px;
	font-weight: 400;
}